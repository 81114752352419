import { useCallback, useMemo } from "react";
import { pdf } from "@react-pdf/renderer";
import React from "react";

import { useFavicon } from "./useFavicon";
import { useCyberScoreCombinedQuery } from "./useCyberScoreCombinedQuery";
import { useLinkedinProfilePics } from "./useLinkedinProfilePics";
import { useReconActionPlanQuery } from "./useReconActionPlanQuery";
import { useReconKeyTakeawaysQuery } from "./useReconKeyTakeawaysQuery";
import PDFScoreCardDocument from "../components/pdf/v2/score-card/PDFScoreCardDocument";
import mspLogo from "../assets/images/pdf/logo.png";
import { Branding } from "../features/branding/types";
import { useImageToBase64Query } from "./useImageToBase64Query";
import PDFDetailedScoreCardDocument from "../components/pdf/v2/score-card/PDFDetailedScoreCardDocument";

export default function usePDFDownloader({
  domain,
  branding = {
    primaryColor: "#0B101B",
    accentColor: "#144EE3",
    yourLogo: mspLogo,
    clientLogo: "",
    fontColor: "#fff",
    contactName: "Michael Bakaic",
    contactEmail: "michael@thecyberscore.com",
    phoneNumber: "",
    slogan: "Cyber Version of a Credit Score",
    mspName: "theCyberScore",
    id: "cyber-score",
  },
}: {
  domain: string | undefined;
  branding?: Branding;
}) {
  const { cyberScore, isLoading: isLoadingCyberScore } =
    useCyberScoreCombinedQuery();

  // const { data: surveyData, questions } = useData();

  const { data: backupLogo, isLoading: loadingFavicon } = useFavicon(domain);

  const { profilePics } = useLinkedinProfilePics(domain, cyberScore);

  const { base64Data: logo, isLoading: loadingLogo } = useImageToBase64Query(
    cyberScore?.Logo
  );
  const [isDownloadingScoreCard, setIsDownloadingScoreCard] =
    React.useState(false);
  const [isDownloadingDetailedScoreCard, setIsDownloadingDetailedScoreCard] =
    React.useState(false);

  // const { base64Data: clientLogo, isLoading: =
  //   useImageToBase64Query(
  //     data,
  //     "https://uploads-ssl.webflow.com/658c7dbe6f7007cb9233ad26/65a868b3220784e87a8fd2e9_Iceberg%20Icon%20-%20Network.png"
  //   );

  const { keyTakeaways: reconKeyTakeaways, isLoading: ktLoading } =
    useReconKeyTakeawaysQuery(cyberScore, domain);

  const { actionPlan, isLoading: apLoading } = useReconActionPlanQuery(
    cyberScore,
    domain
  );

  const scoreDoc = useMemo(() => {
    if (
      cyberScore === undefined ||
      actionPlan === undefined ||
      reconKeyTakeaways === undefined
    ) {
      return null;
    }
    return (
      <PDFScoreCardDocument
        company={domain ?? ""}
        logo={logo ?? backupLogo ?? ""}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={reconKeyTakeaways}
        branding={
          backupLogo
            ? { ...branding, clientLogo: logo ?? backupLogo }
            : branding
        }
        whiteLabelLogo={branding.yourLogo}
        profilePics={profilePics}
      ></PDFScoreCardDocument>
    );
  }, [
    logo,
    cyberScore,
    actionPlan,
    reconKeyTakeaways,
    profilePics,
    backupLogo,
    domain,
  ]);

  const isLoading = useMemo(
    () =>
      isLoadingCyberScore ||
      ktLoading ||
      apLoading ||
      loadingFavicon ||
      loadingLogo ||
      scoreDoc === null,

    [
      isLoadingCyberScore,
      loadingFavicon,
      ktLoading,
      apLoading,
      loadingLogo,
      scoreDoc,
    ]
  );
  const savePDFScore = useCallback(async () => {
    console.log("savePDFScore", {
      scoreDoc,
    });
    if (scoreDoc === null) {
      return;
    }
    setIsDownloadingScoreCard(true);
    const asPdf = pdf(scoreDoc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${branding.mspName} - ${
      branding.pdfTitle || "Cyber Score"
    } Card - ${domain}.pdf`;
    link.click();
    setIsDownloadingScoreCard(false);
  }, [scoreDoc, domain, branding]);

  const downloadDetailedScoreCard = useCallback(async () => {
    if (
      !domain ||
      !cyberScore ||
      !actionPlan ||
      !reconKeyTakeaways ||
      !branding
    ) {
      return;
    }
    setIsDownloadingDetailedScoreCard(true);

    const asPdf = pdf(
      <PDFDetailedScoreCardDocument
        company={domain ?? ""}
        logo={logo ?? backupLogo ?? ""}
        cyberScore={cyberScore}
        actionPlan={actionPlan}
        keyTakeaways={reconKeyTakeaways}
        branding={branding}
        whiteLabelLogo={branding.yourLogo}
        profilePics={profilePics}
      ></PDFDetailedScoreCardDocument>
    );
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${new Date()
      .toLocaleDateString("en-CA")
      .split("/")
      .join("-")} ${branding.mspName} - ${
      branding.pdfTitle || "Detailed Score"
    } Card - ${domain}.pdf`;
    link.click();

    setIsDownloadingDetailedScoreCard(false);
  }, [
    branding,
    logo,
    cyberScore,
    actionPlan,
    reconKeyTakeaways,
    profilePics,
    backupLogo,
    domain,
  ]);

  return {
    savePDFScore,
    downloadDetailedScoreCard,
    isLoading,
    isDownloadingScoreCard: isDownloadingScoreCard || isLoading,
    isDownloadingDetailedScoreCard: isDownloadingDetailedScoreCard || isLoading,
  };
}
