import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCyberScoreCombined,
  CyberScoreCombined,
} from "./services/getCyberScoreCombined";
import { useBranding } from "../features/branding/hooks";
import { useScoreClaimListQuery } from "../features/score-claim";
import { createActivity } from "../features/activity-feed/services/createActivity";
import { Activity } from "../features/activity-feed/types/activity";

export function useCyberScoreCombinedQuery() {
  const navigate = useNavigate();
  // const [sanitizedDomain, setSanitizedDomain] = useState('');

  const { branding } = useBranding();
  const { domain } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // If no domain is provided, redirect to root or handle accordingly
    if (!domain) {
      navigate(`/?${searchParams.toString()}`);
      return;
    }
  }, [domain]);

  const { approvedClaim, isLoading: claimLoading } = useScoreClaimListQuery({
    domain,
  });

  const { mutateAsync: createVisit } = useMutation({
    mutationKey: ["create activity (visit)"],
    mutationFn: async () => {
      if (!branding?.memberId) return;
      const activity: Activity = {
        message: `Branded Web Page triggered with ${domain}`,
        activity_code: 102,
        supplement_data: {
          Domain: domain ?? "",
        },
      };

      await createActivity(branding?.memberId, activity);
    },
  });

  const {
    data: cyberScore,
    isLoading,
    isError,
  } = useQuery<CyberScoreCombined>({
    queryKey: ["cyber-score", "combined", domain],
    queryFn: async () => {
      const score = await getCyberScoreCombined(
        domain as string,
        branding?.memberId
      );
      createVisit();
      return score;
    },
    enabled: !!domain,
    retry: 3,
    retryDelay: 25000,
  });

  const Score = useMemo(() => {
    if (!cyberScore) return 0;
    if (approvedClaim) {
      const scores = [
        approvedClaim.mfaAnswer,
        approvedClaim.antiVirusAnswer,
        approvedClaim.trainingAnswer,
        approvedClaim.insuredAnswer,
        approvedClaim.resolvedBreaches === 1 ? 1 : cyberScore.BDVScore,
        cyberScore.SEGScore,
        cyberScore.ESSScore,
        cyberScore.ISHScore,
        cyberScore.WIScore,
        cyberScore.WAFScore,
      ];
      console.log(scores);
      return (
        (scores.filter((answer) => answer === 1).length * 100) / scores.length
      );
    }
    return cyberScore.Score;
  }, [approvedClaim, cyberScore, approvedClaim]);

  const revisedCyberScore = useMemo(
    () =>
      approvedClaim?.resolvedBreaches === 1
        ? ({
            ...cyberScore,
            Score,
            BreachList: [],
            BDVScore: 1,
          } as CyberScoreCombined)
        : ({ ...cyberScore, Score } as CyberScoreCombined),
    [approvedClaim, cyberScore, Score]
  );

  return {
    cyberScore: revisedCyberScore,
    isLoading: isLoading || claimLoading,
    isError,
    sanitizedDomain: domain,
  };
}
