import { CyberScoreCombined } from "../../../../../hooks/services/getCyberScoreCombined";
import { BreachTableItem } from "../types";

export function getBreachTableItems(cyberScore: CyberScoreCombined) {
  const { BreachList } = cyberScore;

  //     .map(() => ({
  //       Email: "admin@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "nasy@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "juliak@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "stephenm@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "info@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "secretariat@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  // ];

  // consolb.log("Breach List", BreachList);

  const items: BreachTableItem[] =
    BreachList?.map((b) => ({
      email: b.Email ?? "",
      date: b.BreachDate ?? "",
      account: b.BreachName ?? "",
    })).sort((a, b) => {
      const aScore = new Date(a.date).getTime();
      const bScore = new Date(b.date).getTime();

      return bScore - aScore;
    }) ?? [];

  return items;
}
