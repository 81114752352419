import {
  Flex,
  Text,
  Button,
  View,
  Image,
  TextField,
  Grid,
  Loader,
  ScrollView,
} from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { useBranding } from "../features/branding/hooks";
import building from "../assets/images/building.svg";
import { useIP } from "../features/ports/hooks";
import { usePortHealth } from "../features/ports/hooks/usePortHealth";
import QuickFactCard from "./QuickFactCard";

export default function ViewIPCard() {
  const { branding, brandingId } = useBranding();

  const { ip } = useIP();

  useEffect(() => {
    setIpInput(ip);
  }, [ip]);

  const [ipInput, setIpInput] = React.useState<string>();
  const [submittedIp, setSubmittedIp] = React.useState<string>();

  const {
    ports,
    isLoading: portsLoading,
    isError: portsError,
  } = usePortHealth(submittedIp);

  if (brandingId !== "view" && brandingId !== "neeve") return null;

  return (
    <>
      <View width={"100%"}>
        <Flex
          width={"100%"}
          alignItems="center"
          justifyContent={"center"}
          gap={"xxl"}
          backgroundColor={branding?.accentColor || "#144EE3"}
          padding={"large"}
        >
          {/* <Grid
            templateColumns={{ base: "1fr", large: "1fr 1fr" }}
            alignItems={"center"}
            gap={"xl"}
            width={"60%"}
          > */}
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            gap={"large"}
            direction={{ base: "column", large: "row" }}
          >
            <Text
              fontSize={{ base: "medium", large: "xl" }}
              width={{ base: "300px", large: "450px" }}
              fontWeight={"bold"}
              color={"white"}
              textAlign={"left"}
              style={{
                textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
              }}
            >
              Enter your IP address to check if your building is exposed to
              hackers
            </Text>
            <Flex alignItems={"center"}>
              <TextField
                width={"100%"}
                placeholder="123.145.124"
                backgroundColor={"#FFFFFF"}
                style={{ border: "none" }}
                borderRadius={"medium"}
                labelHidden
                size="large"
                label=""
                value={ipInput}
                onChange={(e) => {
                  setIpInput(e.target.value);
                }}
              />

              <Button
                color={"white"}
                borderRadius={"medium"}
                backgroundColor={branding?.primaryColor}
                fontSize={"large"}
                whiteSpace={"nowrap"}
                // data-attr="download-score-card"
                // data-ph-capture-attribute-domain={domain}
                // data-ph-capture-attribute-cyberScore={JSON.stringify(cyberScore)}
                onClick={() => {
                  setSubmittedIp(ipInput);
                }}
              >
                Search
              </Button>
            </Flex>
          </Flex>

          {/* <Flex justifyContent={"center"}></Flex> */}
          {/* </Grid> */}
        </Flex>
      </View>

      <View width={"100%"} maxWidth={"1000px"} padding={"xl 0"}>
        <Grid
          templateColumns={{ base: "100%", medium: "1fr 2fr" }}
          gap={"small"}
        >
          <Flex justifyContent={"center"} height={"100%"} alignItems={"center"}>
            <Image
              alt="Image of building"
              src={building}
              maxHeight={"250px"}
              maxWidth={"175px"}
              objectFit={"contain"}
            ></Image>
          </Flex>
          <ScrollView maxHeight={"350px"} width={"100%"} padding={"medium xl"}>
            <Flex direction={"column"}>
              {portsLoading ? (
                <Loader />
              ) : !ports || portsError ? (
                <QuickFactCard
                  padding="medium"
                  score={1}
                  title={"Secured"}
                  information={"No services exposed to the public internet"}
                  right="0"
                  left=""
                  transform="translate(60%, -25%)"
                />
              ) : (
                ports.map((port) => (
                  <QuickFactCard
                    padding="medium"
                    score={-1}
                    title={"Risk:"}
                    information={
                      <Text>
                        Exposed port{" "}
                        <Text as="span" fontWeight={"bold"}>
                          {port.port}
                        </Text>{" "}
                        {port.descriptor}
                      </Text>
                    }
                    right="0"
                    left=""
                    transform="translate(60%, -25%)"
                  />
                ))
              )}
            </Flex>
          </ScrollView>
        </Grid>
      </View>
    </>
  );
}
