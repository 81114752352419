import * as React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import gauge from "../../../assets/images/pdf/gauge.png";
import { CyberScoreCombined } from "../../../hooks/services/getCyberScoreCombined";
import { Branding } from "../../../features/branding/types";

export const CyberScore = ({
  cyberScore,
  branding,
}: {
  cyberScore: CyberScoreCombined | undefined;
  branding: Branding;
}) => (
  <View style={{ height: "100%", width: "200px" }}>
    <Image
      src={gauge}
      style={{
        width: "100%",
        height: "auto",
        position: "absolute",
      }}
    />

    <View
      style={{
        justifyContent: "center",
        height: "120px",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: "48px",
          paddingTop: "40px",
          fontWeight: "black",
        }}
      >
        {cyberScore?.Score.toFixed(0)}%
      </Text>
      <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
        {branding.pdfTitle || "Cyber Score"}
      </Text>
    </View>
  </View>
);
