import { View, Text, Image } from "@react-pdf/renderer";
import React, { useMemo } from "react";

import steps from "../../../../assets/images/pdf/steps.png";
import agent from "../../../../assets/images/pdf/agent black.png";
import { Branding } from "../../../../features/branding/types";
import { PageLayout } from "../PageLayout";
import { Subtitle } from "../Subtitle";
import { Title } from "../Title";
import { getDarkWebTableItems } from "./utils/getDarkWebTableItems";
import { DarkWebTableItem } from "./types";
import { BreachTable } from "./BreachTable";

type Props = {
  branding: Branding;
  cyberScore: any;
  docType?: "Card" | "Report";
};

export default function DarkWebFullReport({
  branding,
  cyberScore,
  docType = "Report",
}: Props): JSX.Element {
  const InfoPanel = () => (
    <View
      style={{
        width: "100%%",
        height: "100vh",
        backgroundColor: branding.primaryColor,
        color: branding.fontColor,
        justifyContent: "space-between",
      }}
    >
      <View>
        {branding.yourLogo !== "" ? (
          <Image
            src={branding.yourLogo}
            style={{
              objectFit: "contain",
              height: "70px",
              width: "100%",
            }}
          ></Image>
        ) : null}

        <View style={{ paddingTop: "15px" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
              gap: "5px",
            }}
          >
            <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
              3 STEP ATTACK
            </Text>
          </View>
          <View style={{ paddingTop: "15px" }}>
            <View
              style={{
                alignItems: "center",
                paddingBottom: "20px",
                gap: "5px",
              }}
            >
              <View style={{ height: "200px", width: "auto" }}>
                <Image
                  src={steps}
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                />
              </View>
            </View>

            <View style={{ fontSize: "10.5px", gap: "5px" }}>
              <Text style={{ fontWeight: "medium", color: branding.fontColor }}>
                What could go wrong:
              </Text>
              <Text>
                Using off-the-shelf marketing tools, criminals find your
                employees' details, then match them with credentials from the
                Dark Web. Armed with emails & passwords, they effortlessly
                access banking, email, and cloud services. This simple but
                effective method exposes your business to serious financial
                risks and data breaches.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Text
        style={{ fontSize: "10.5px", paddingBottom: "12px" }}
        render={({ pageNumber, totalPages }) =>
          `${new Date().toLocaleString("en-us", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })} | Page ${pageNumber}/${totalPages}`
        }
      />
    </View>
  );

  const tableItems = useMemo(
    () => getDarkWebTableItems(cyberScore),
    [cyberScore]
  );

  // split items into groups of 20
  const paginatedItems = React.useMemo(() => {
    const paginatedItems: DarkWebTableItem[][] = [];
    for (let i = 0; i < tableItems.length; i += 20) {
      paginatedItems.push(tableItems.slice(i, i + 20));
    }
    return paginatedItems;
  }, [tableItems]);

  return (
    <>
      {paginatedItems.map((items, i) => (
        <PageLayout
          key={i}
          reportStyles={branding}
          left={
            <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
              <View
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Title
                  title={`${branding.pdfTitle || "Cyber Score"} ${docType}`}
                  reportStyles={branding}
                ></Title>
                <View
                  style={{
                    padding: "10px 20px 0px 0px",
                    gap: "10px",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      gap: "15px",
                    }}
                  >
                    <View
                      style={{
                        gap: "10px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Subtitle
                        icon={agent}
                        titlepart1={"Dark Web"}
                        titlepart2={"Full Report"}
                        color={branding.accentColor}
                      />
                      <Text
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {i + 1}/{paginatedItems.length} - All Monitored{" "}
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: branding.accentColor,
                          }}
                        >
                          Accounts
                        </Text>
                      </Text>
                    </View>
                    <View style={{ gap: "20px" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            gap: "10px",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "black",
                              fontSize: "15px",
                              color: "#FF343A",
                            }}
                          >
                            {cyberScore.BreachList.length}
                          </Text>
                          <Text style={{ fontSize: "10px" }}>
                            Email accounts for sale on the Dark Web
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            gap: "10px",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "black",
                              fontSize: "15px",
                              color: "#FF343A",
                            }}
                          >
                            {cyberScore.Employees.length}
                          </Text>
                          <Text style={{ fontSize: "10px" }}>
                            Total accounts scanned
                          </Text>
                        </View>
                      </View>

                      <BreachTable items={items} reportStyles={branding} />
                    </View>
                  </View>

                  <View
                    style={{
                      gap: "2px",
                      fontSize: "10px",
                      paddingBottom: "12px",
                    }}
                  >
                    <Text style={{ textAlign: "right" }}>
                      {branding.slogan ? `${branding.slogan} |` : ""}{" "}
                      {branding.contactName} | {branding.contactEmail}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          }
          right={<InfoPanel />}
        ></PageLayout>
      ))}
    </>
  );
}
