import {
  Button,
  Card,
  Flex,
  Link,
  Placeholder,
  ScrollView,
  TableCell,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { FaLinkedin } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useCyberScoreCombinedQuery } from "../hooks/useCyberScoreCombinedQuery";
import { useBranding } from "../features/branding/hooks";
import { useCallToActionContext } from "../features/call-to-actions/contexts/CallToActionContext";
import { useViewAccess } from "../features/call-to-actions/hooks";

type TableItem = {
  email: string;
  nBreaches: number;
  name: string;
  position: string;
  linkedInUrl: string | null;
};

type CompromisedAccountsTableProps = {
  maxHeight: string;
};

export default function CompromisedAccountsTable(
  props: CompromisedAccountsTableProps
) {
  const { maxHeight } = props;

  const { cyberScore, isLoading } = useCyberScoreCombinedQuery();
  const { branding } = useBranding();

  const tableItems = useMemo<TableItem[]>(
    () =>
      (
        cyberScore?.Employees.map((employee) => {
          const email = employee.Email ?? "";

          // count occurences of email in BreachList
          const nBreaches = (
            cyberScore.BreachList?.filter((breach) => breach.Email === email) ??
            []
          ).length;

          const { FirstName, LastName, Position } = employee;

          const f = FirstName !== null ? FirstName : "";

          const l = LastName !== null ? LastName : "";

          const name = `${f} ${l}`.trim() || "";
          const position = Position ?? "";

          return {
            email,
            nBreaches: nBreaches,
            name: name,
            position: position,
            linkedInUrl: employee.SourcePage,
          };
        }) ?? []
      ).sort((a, b) => b.nBreaches - a.nBreaches),
    [cyberScore]
  );

  const { openModal } = useCallToActionContext();

  const { detailsHidden } = useViewAccess();

  if (tableItems.length === 0) {
    return <Text>No compromised accounts found</Text>;
  }

  return (
    <>
      <View position={"relative"}>
        {detailsHidden ? (
          <Flex
            position={"absolute"}
            top={"0"}
            left={"0"}
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              pointerEvents: "none",
            }}
          >
            <Button
              variation="primary"
              backgroundColor={branding.primaryColor}
              onClick={(e) => {
                e.stopPropagation();
                openModal("compromised-accounts-table");
              }}
              style={{
                pointerEvents: "auto",
                zIndex: 1,
              }}
            >
              Unlock your full Score Card
            </Button>
          </Flex>
        ) : null}
        <ScrollView height={maxHeight} padding={"xs"}>
          <Flex direction={"column"}>
            {isLoading ? (
              [0, 1, 2, 3, 4, 5].map((i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Placeholder />
                  </TableCell>
                  <TableCell>
                    <Placeholder />
                  </TableCell>
                  <TableCell>
                    <Placeholder />
                  </TableCell>
                  <TableCell>
                    <Placeholder />
                  </TableCell>
                </TableRow>
              ))
            ) : tableItems.length === 0 ? (
              <TableRow>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            ) : (
              tableItems.map((item, i) => (
                <Card
                  borderRadius={"medium"}
                  variation="elevated"
                  key={i}
                  onClick={() => {
                    if (detailsHidden && i >= 2) {
                      openModal("compromised-accounts-table-blur");
                      return;
                    }
                    navigator.clipboard.writeText(item.email);
                    toast.success(
                      <>
                        <b>{item.email}</b> copied to clipboard 📋
                      </>
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Flex>
                    <Flex flex={1} direction={"column"} gap={"xxxs"}>
                      {item.name ? (
                        <Link
                          href={item.linkedInUrl ?? undefined}
                          isExternal
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            filter:
                              detailsHidden && i >= 2 ? "blur(8px)" : undefined,
                            pointerEvents:
                              detailsHidden && i >= 2 ? "none" : undefined,
                          }}
                        >
                          {item.linkedInUrl ? <FaLinkedin /> : null} {item.name}
                        </Link>
                      ) : null}
                      <Text
                        style={{
                          filter:
                            detailsHidden && i >= 2 ? "blur(8px)" : undefined,
                        }}
                      >
                        {item.email}
                      </Text>
                      <Text
                        fontWeight={"bold"}
                        style={{
                          filter:
                            detailsHidden && i >= 2 ? "blur(8px)" : undefined,
                        }}
                      >
                        {item.position}
                      </Text>
                    </Flex>
                    <Flex direction={"column"} gap={"0"}>
                      <Text
                        fontWeight={"black"}
                        fontSize={"xxxl"}
                        height={"xxl"}
                        color={item.nBreaches !== 0 ? "red" : "black"}
                        style={{
                          filter:
                            detailsHidden && i >= 2 ? "blur(8px)" : undefined,
                        }}
                      >
                        {item.nBreaches}
                      </Text>
                      <Text
                        fontWeight={"bold"}
                        color={item.nBreaches !== 0 ? "red" : "black"}
                        style={{
                          filter:
                            detailsHidden && i >= 2 ? "blur(8px)" : undefined,
                        }}
                      >
                        Breaches
                      </Text>
                    </Flex>
                  </Flex>
                </Card>

                // <TableRow key={i}>
                //   <TableCell padding={"3px xs 3px xs"}

                //     fontSize={"smaller"}
                //     textAlign={"start"}
                //     whiteSpace={"nowrap"}
                //     onClick={() => {
                //       navigator.clipboard.writeText(item.email);
                //       toast.success(
                //         <>
                //           <b>{item.email}</b> copied to clipboard 📋
                //         </>
                //       );
                //     }}
                //   >
                //     <Text isTruncated width={"125px"}>
                //       {" "}
                //       {item.email}
                //     </Text>

                //     <View as={"span"} className="copy" padding={"0 0 0 xs"}>
                //       <FaClipboard className="copy-hover" size={"15px"} />
                //     </View>
                //   </TableCell>
                //   <TableCell padding={"3px xs 3px xs"}
                //     fontSize={"smaller"}
                //     whiteSpace={"nowrap"}
                //     textAlign={"start"}
                //   >
                //     {item.linkedInUrl ? (
                //       <Link href={item.linkedInUrl} isExternal>
                //         <FaLinkedin />{" "}
                //       </Link>
                //     ) : null}
                //     {item.name}
                //   </TableCell>
                //   <TableCell padding={"3px xs 3px xs"}
                //     whiteSpace={"nowrap"}
                //     fontSize={"smaller"}
                //     textAlign={"start"}
                //   >
                //     {item.position}
                //   </TableCell>
                //   <TableCell padding={"3px xs 3px xs"}  fontSize={"smaller"} textAlign={"center"}>
                //     {item.nBreaches}
                //   </TableCell>
                // </TableRow>
              ))
            )}
          </Flex>
        </ScrollView>
      </View>
    </>
  );
}
