import * as React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import mailbox from "../../../../assets/images/pdf/mailbox.png";
import stepsEmail from "../../../../assets/images/pdf/steps-email.png";
import { Branding } from "../../../../features/branding/types";
import { CyberScoreCombined } from "../../../../hooks/services/getCyberScoreCombined";
import { Subtitle } from "../Subtitle";
import { Title } from "../Title";
import { TitledCommentary } from "../WhyItMatters";

export const EmailImpersonationReport = ({
  cyberScore,
  branding,
  docType = "Report",
}: {
  cyberScore: CyberScoreCombined;
  branding: Branding;
  docType?: "Card" | "Report";
}) => {
  const DMARCPass: boolean =
    cyberScore.ESSScore === 1 ||
    (cyberScore.ESSScore === -1 && !cyberScore.ESS.includes("DMARC"));

  return (
    <View style={{ fontFamily: "Rubik", flexDirection: "row" }}>
      <View
        style={{
          width: "70%",
          height: "100vh",
          padding: "20px 0px 5px 20px",
        }}
      >
        <Title
          title={`${branding.pdfTitle || "Cyber Score"} ${docType}`}
          reportStyles={branding}
        ></Title>
        <View
          style={{
            padding: "10px 20px 0px 0px",
            gap: "10px",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={{ gap: "20px" }}>
            <View style={{ gap: "15px" }}>
              <Subtitle
                icon={mailbox}
                titlepart1={"EMAIL IMPERSONATION"}
                titlepart2={"REPORT"}
                color={branding.accentColor}
              />

              <TitledCommentary
                reportStyles={branding}
                header={"Why it matters:"}
                body={
                  "Email fraud can devastate a small business where criminals can forge your identity to trick clients or employees, risking finances, and reputation. Without SPF, DKIM, and DMARC, your email domain is an open door to such impersonation and deceit."
                }
              />
            </View>

            {/* Pass/Fail Table */}
            <View
              style={{
                fontSize: "10px",
                gap: "3px",
                padding: "0px 25px",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "2px",
                  borderBottomColor: branding.accentColor,
                }}
              >
                <Text style={{ fontWeight: "bold", width: "100px" }}>
                  Status
                </Text>
                <Text style={{ fontWeight: "bold", width: "370px" }}>
                  Record
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "100px", justifyContent: "center" }}>
                  <View
                    style={{
                      width: "50px",
                      backgroundColor: cyberScore.SPF ? "#00B050" : "#FF343A",
                      color: "white",
                      padding: "2px 0px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{cyberScore.SPF ? "Pass" : "Fail"}</Text>
                  </View>
                </View>
                <Text style={{ width: "370px" }}>
                  {cyberScore.SPF ?? "No SPF records found."}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "100px", justifyContent: "center" }}>
                  <View
                    style={{
                      width: "50px",
                      backgroundColor: cyberScore.DKIM ? "#00B050" : "#FF343A",
                      color: "white",
                      padding: "2px 0px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{cyberScore.DKIM ? "Pass" : "Fail"}</Text>
                  </View>
                </View>
                <Text style={{ width: "370px" }}>
                  {cyberScore.DKIM
                    ? cyberScore.DKIM?.slice(0, 55) + "…"
                    : "No DKIM records found."}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "100px", justifyContent: "center" }}>
                  <View
                    style={{
                      width: "50px",
                      backgroundColor: DMARCPass ? "#00B050" : "#FF343A",
                      color: "white",
                      padding: "2px 0px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{DMARCPass ? "Pass" : "Fail"}</Text>
                  </View>
                </View>
                <Text style={{ width: "370px" }}>
                  {cyberScore.DMARC === null
                    ? "No DMARC records found. We recommend you use: v=DMARC1; p=reject;"
                    : !DMARCPass
                    ? cyberScore.DMARC +
                      " We recommend you use: v=DMARC1; p=reject;"
                    : cyberScore.DMARC}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "100px", justifyContent: "center" }}>
                  <View
                    style={{
                      width: "50px",
                      backgroundColor:
                        cyberScore.SEGScore === 1 ? "#00B050" : "#FF343A",
                      color: "white",
                      padding: "2px 0px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <Text>{cyberScore.SEGScore === 1 ? "Pass" : "Fail"}</Text>
                  </View>
                </View>
                <Text style={{ width: "370px" }}>
                  {cyberScore.SEGScore === 1
                    ? cyberScore.SEG
                    : cyberScore.SEGScore === -1
                    ? `No email service detected that provides spam/phishing protection.`
                    : "No email service associated with this domain."}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ gap: "25px" }}>
            <TitledCommentary
              reportStyles={branding}
              header={"How to fix it:"}
              body={
                "Implementing SPF, DKIM, and DMARC seals your email domain against imposters, safeguarding your business's communications. This trio verifies emails' authenticity, blocking fraudsters, protecting your finances, and maintaining your reputation. It's a clear step towards securing your business's integrity and trustworthiness."
              }
            />

            <View
              style={{
                gap: "2px",
                fontSize: "10px",
                paddingBottom: "12px",
              }}
            >
              <Text style={{ textAlign: "right" }}>
                {branding.slogan ? `${branding.slogan} |` : ""}{" "}
                {branding.contactName} | {branding.contactEmail}
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* Right Side Panel   */}
      <View
        style={{
          width: "30%",
          backgroundColor: branding.primaryColor,
          height: "100vh",
          color: branding.fontColor,
          padding: "20px 20px 5px 20px",
          justifyContent: "space-between",
        }}
      >
        <View>
          {branding.yourLogo !== "" ? (
            <Image
              src={branding.yourLogo}
              style={{
                objectFit: "contain",
                height: "70px",
                width: "100%",
              }}
            ></Image>
          ) : null}

          <View style={{ paddingTop: "15px" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "20px",
                gap: "5px",
              }}
            >
              <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                3 STEP ATTACK
              </Text>
            </View>

            <View
              style={{
                alignItems: "center",
                paddingBottom: "20px",
                gap: "5px",
              }}
            >
              <View style={{ height: "200px", width: "auto" }}>
                <Image
                  src={stepsEmail}
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                />
              </View>
            </View>

            <View style={{ fontSize: "10.5px", gap: "5px" }}>
              <Text style={{ fontWeight: "medium", color: branding.fontColor }}>
                What could go wrong:
              </Text>
              <Text>
                DNS records are the internet's phone book. Criminals can easily
                spot your email system's weaknesses, as straightforward as
                reading the phone book. They can then automatically send emails
                to your employees, masquerading as you, the CEO, or HR. Who
                wouldn't open an email titled 'Vacation Policy Updates' from HR?
              </Text>
            </View>
          </View>
        </View>

        <Text
          style={{ fontSize: "10.5px", paddingBottom: "12px" }}
          render={({ pageNumber, totalPages }) =>
            `${new Date().toLocaleString("en-us", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} | Page ${pageNumber}/${totalPages}`
          }
        />
      </View>
    </View>
  );
};
