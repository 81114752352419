import { LinkedInProfilePic } from "../types/profile-pic";
import { getLinkedInPersonPFP } from "./getLinkedInPersonPFP";

export async function tryGetProfilePicutes({
  count = 2,
  urls,
}: {
  count?: number;
  urls: string[];
}) {
  const profilePics: LinkedInProfilePic[] = [];
  for (let i = 0; i < urls.length && profilePics.length < count; i++) {
    const profile = urls[i];
    //   index: i,
    //   total: profiles.length,
    // });
    // setCurrentProfile(profile);
    let nRetries = 0;
    const maxRetries = 1;
    while (nRetries <= maxRetries) {
      try {
        console.log(
          `Fetching profile pic for ${profile}. Attempt ${nRetries + 1}`
        );
        const profilePic = await getLinkedInPersonPFP(profile);
        console.log(`Done fetching profile ${profile}`);
        if (!profilePic.isDefault) profilePics.push(profilePic);
        break;
      } catch (e: any) {
        if (e.name === "429") {
          nRetries++;
          console.error("Rate limited, waiting 65 seconds before retrying");
          await new Promise((resolve) => setTimeout(resolve, 65000));
        } else {
          console.error(`Error fetching profile pic for ${profile}`);
          break;
        }
      }
    }
  }
  const profilePicData = profilePics.map((p) => p.data);

  return profilePicData;
}
