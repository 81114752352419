import { View, Text, Image } from "@react-pdf/renderer";
import React from "react";

import agent from "../../../../assets/images/pdf/agent black.png";

import steps from "../../../../assets/images/pdf/steps.png";
import { Branding } from "../../../../features/branding/types";
import { CyberScoreCombined } from "../../../../hooks/services/getCyberScoreCombined";
import { BreachTable } from "../BreachTable";
import { PageLayout } from "../PageLayout";
import { Subtitle } from "../Subtitle";
import { Title } from "../Title";
import { TitledCommentary } from "../WhyItMatters";

type Props = {
  docType?: "Card" | "Report";
  branding: Branding;
  cyberScore: CyberScoreCombined;
};

export default function DarkWebReportSummary({
  branding,
  cyberScore,
  docType = "Report",
}: Props) {
  return (
    <PageLayout
      reportStyles={branding}
      left={
        <>
          <Title
            title={`${branding.pdfTitle || "Cyber Score"} ${docType}`}
            reportStyles={branding}
          ></Title>

          <View
            style={{
              padding: "10px 20px 0px 0px",
              gap: "10px",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <View style={{ gap: "20px" }}>
              <View style={{ gap: "15px" }}>
                <Subtitle
                  icon={agent}
                  titlepart1={"DARK WEB"}
                  titlepart2={"REPORT SUMMARY"}
                  color={branding.accentColor}
                />

                <TitledCommentary
                  reportStyles={branding}
                  header="Why it matters:"
                  body={
                    "Discovering your employees' work emails and passwords on the dark web spells serious trouble. It's a gold mine for cybercriminals. Armed with your team's emails and passwords from the Dark Web, they can raid your assets in seconds, not days. This isn't just risky; it's a direct threat to your business's survival."
                  }
                />
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    gap: "10px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "black",
                      fontSize: "32px",
                      color: "#FF343A",
                    }}
                  >
                    {cyberScore.BreachList?.length}
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    Email accounts for sale on the Dark Web
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    gap: "10px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "black",
                      fontSize: "32px",
                      color: "#FF343A",
                    }}
                  >
                    {cyberScore.Employees.length}
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    Total Accounts Scanned
                  </Text>
                </View>
              </View>

              <BreachTable cyberScore={cyberScore} reportStyles={branding} />
            </View>

            <View style={{ gap: "25px" }}>
              <TitledCommentary
                reportStyles={branding}
                header="How to fix it:"
                body={
                  "By implementing Multi-Factor Authentication and ongoing Dark Web monitoring, even if passwords are exposed, your business remains sealed. Turn your vulnerabilities into strengths, safeguarding your future and setting you apart from the competition."
                }
              />

              <View
                style={{
                  gap: "2px",
                  fontSize: "10px",
                  paddingBottom: "12px",
                }}
              >
                <Text style={{ textAlign: "right" }}>
                  {branding.slogan ? `${branding.slogan} |` : ""}{" "}
                  {branding.contactName} | {branding.contactEmail}
                </Text>
              </View>
            </View>
          </View>
        </>
      }
      right={
        <>
          <View>
            <View
              style={{
                height: "75px",
                width: "90%",
                alignSelf: "center",
                borderRadius: "20px",
              }}
            >
              {branding.yourLogo !== "" ? (
                <Image
                  src={branding.yourLogo}
                  style={{
                    objectFit: "contain",
                    height: "70px",
                    width: "100%",
                  }}
                ></Image>
              ) : null}
            </View>

            <View style={{ paddingTop: "15px" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "20px",
                  gap: "5px",
                }}
              >
                <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                  3 STEP ATTACK
                </Text>
              </View>

              <View
                style={{
                  alignItems: "center",
                  paddingBottom: "20px",
                  gap: "5px",
                }}
              >
                <View style={{ height: "200px", width: "auto" }}>
                  <Image
                    src={steps}
                    style={{
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </View>
              </View>

              <View style={{ fontSize: "10.5px", gap: "5px" }}>
                <Text
                  style={{ fontWeight: "medium", color: branding.fontColor }}
                >
                  What could go wrong:
                </Text>
                <Text>
                  Using off-the-shelf marketing tools, criminals find your
                  employees' details, then match them with credentials from the
                  Dark Web. Armed with emails & passwords, they effortlessly
                  access banking, email, and cloud services. This simple but
                  effective method exposes your business to serious financial
                  risks and data breaches.
                </Text>
              </View>
            </View>
          </View>

          <Text
            style={{ fontSize: "10.5px", paddingBottom: "12px" }}
            render={({ pageNumber, totalPages }) =>
              `${new Date().toLocaleString("en-us", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })} | Page ${pageNumber}/${totalPages}`
            }
          />
        </>
      }
    />
  );
}
