import * as React from "react";
import { Text, View } from "@react-pdf/renderer";
import { Branding } from "../../../../features/branding/types";
import { DarkWebTableItem } from "./types";

export const BreachTable = ({
  items,
  reportStyles,
}: {
  items: DarkWebTableItem[];
  reportStyles: Branding;
}) => {
  return (
    <View style={{ fontSize: "10px" }}>
      <View
        style={{
          flexDirection: "row",
          borderBottom: "2px",
          borderBottomColor: reportStyles.accentColor,
          gap: "5px",
        }}
      >
        <Text style={{ fontWeight: "bold", width: "150px" }}>Top Accounts</Text>
        <Text style={{ fontWeight: "bold", width: "150px" }}>Name</Text>
        <Text style={{ fontWeight: "bold", width: "150px" }}>Position</Text>
        <Text
          style={{
            fontWeight: "bold",
            width: "70px",
            textAlign: "center",
          }}
        >
          Breaches
        </Text>
      </View>
      <View
        style={{
          paddingTop: "5px",
        }}
      >
        {items.map((item, i) => (
          <View
            key={i}
            style={{ flexDirection: "row", gap: "5px", height: "18px" }}
          >
            <Text
              style={{
                width: "180px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.email}
            </Text>
            <Text
              style={{
                width: "180px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.name}
            </Text>
            <Text
              style={{
                width: "180px",
                maxLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {item.position}
            </Text>
            <Text
              style={{
                width: "80px",
                color: "#FF343A",
                fontWeight: "bold",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {item.nBreaches}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};
