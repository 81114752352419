import React from "react";
import { Marquee } from "../../../components/Marquee";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { FaArrowRight } from "react-icons/fa";
import { useBranding } from "../../branding/hooks";
import usePDFDownloader from "../../../hooks/usePDFDownloader";
import { useParams } from "react-router-dom";
import { useViewAccess } from "../hooks";
import { useCallToActionContext } from "../contexts/CallToActionContext";
import { FaDownload, FaUnlock } from "react-icons/fa6";
import { CyberScoreCombined } from "../../../hooks/services/getCyberScoreCombined";

export default function ScoreCardMarquee({
  cyberScore,
  source,
}: {
  cyberScore: CyberScoreCombined;
  source: string;
}) {
  const { domain } = useParams();

  const { brandingId, branding, isLoading: isBrandingLoading } = useBranding();

  const { savePDFScore, isDownloadingScoreCard: isDownloaderLoading } =
    usePDFDownloader({
      domain,
      branding,
    });
  const { openModal } = useCallToActionContext();
  const { detailsHidden } = useViewAccess();

  return (
    <Marquee>
      {detailsHidden ? (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          gap={"large"}
          direction={{ base: "column", large: "row" }}
        >
          <Text
            textAlign={"left"}
            style={{
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Text
              fontSize={{ base: "medium", large: "xl" }}
              width={{ base: "225px", large: "350px" }}
              fontWeight={"bold"}
              color={"white"}
            >
              {cyberScore.BreachList?.length && cyberScore.BreachList.length > 0
                ? "Want to see which employees emails are on the Dark Web?"
                : `Want to see your full ${
                    branding.pdfTitle || "Cyber Score"
                  } Card?`}
            </Text>
          </Text>
          <Flex alignItems={"center"}>
            <FaArrowRight
              // className="cta-arrow"
              color={"white"}
              size={"2em"}
            />
            <Button
              className="cta-button"
              color={branding?.accentColor || "#144EE3"}
              borderRadius={"medium"}
              backgroundColor="white"
              fontSize={"large"}
              gap={"small"}
              onClick={() => {
                openModal(source);
              }}
            >
              <FaUnlock /> Unlock your full Score Card
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          gap={"large"}
          direction={{ base: "column", large: "row" }}
        >
          <Text
            textAlign={"left"}
            style={{
              textShadow: "2px 2px 2px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Text
              fontSize={{ base: "medium", large: "xl" }}
              width={{ base: "225px", large: "350px" }}
              fontWeight={"bold"}
              color={"white"}
            >
              Click here to download your full{" "}
              {branding.pdfTitle || "Cyber Score"} Card
            </Text>
          </Text>
          <Flex alignItems={"center"}>
            <FaArrowRight
              // className="cta-arrow"
              color={"white"}
              size={"2em"}
            />
            <Button
              className="cta-button"
              color={branding?.accentColor || "#144EE3"}
              borderRadius={"medium"}
              backgroundColor="white"
              fontSize={"large"}
              gap={"small"}
              loadingText="Score Card"
              isLoading={isDownloaderLoading || isBrandingLoading}
              data-attr="download-score-card"
              data-ph-capture-attribute-domain={domain}
              data-ph-capture-attribute-brandingid={brandingId}
              data-ph-capture-attribute-mspname={branding?.mspName}
              data-ph-capture-attribute-mpsid={branding?.memberId}
              // data-ph-capture-attribute-cyberScore={JSON.stringify(cyberScore)}
              onClick={() => {
                savePDFScore();
              }}
            >
              <FaDownload /> Score Card
            </Button>
          </Flex>
        </Flex>
      )}
    </Marquee>
  );
}
