import { useQueries, useQuery } from "@tanstack/react-query";
import { tryGetProfilePicutes } from "../features/profile-pictures/services/TryGetProfilePictures";
import { CyberScoreCombined } from "./services/getCyberScoreCombined";

export function useLinkedinProfilePics(
  domain: string | undefined | null,
  cyberScore: CyberScoreCombined | undefined
) {
  const { data: profilePicUrls, status } = useQuery({
    queryKey: [
      "li-profile-pics",
      "urls",
      domain,
      cyberScore?.Employee1,
      cyberScore?.Employee2,
    ],
    queryFn: async () => {
      if (cyberScore?.Employee1 === null && cyberScore?.Employee2 === null) {
        const profiles = cyberScore.Employees.map((e) => e.SourcePage)
          .filter((p) => p !== null)
          .filter((p, index, self) => self.indexOf(p) === index)
          .slice(0, 5) as string[];
        console.log(
          "cyberScore.Employee Pictures are null, searching for profiles",
          profiles
        );
        const profilePicData = await tryGetProfilePicutes({
          urls: profiles,
        });
        return profilePicData;
      } else {
        return [cyberScore?.Employee1, cyberScore?.Employee2].filter(
          (e) => !!e
        ) as string[];
      }
    },
    enabled: !!cyberScore && !!domain,
  });

  const queries = useQueries({
    queries:
      profilePicUrls?.map((url) => ({
        queryKey: ["li-profile-pic", url],
        queryFn: async () => url,
        enabled: !!url,
      })) ?? [],
  });

  const profilePics = queries
    .map((query) => query.data)
    .filter(Boolean) as string[];

  const isLoading =
    queries.some((query) => query.isLoading) || status !== "success";

  return {
    profilePics,
    isLoading,
  };
}
