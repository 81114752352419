import React from "react";
import CyberCenterContent from "./CyberCenterContent";
import { CyberScoreCombined } from "../hooks/services/getCyberScoreCombined";
import { useScoreClaimListQuery } from "../features/score-claim";
import { useParams } from "react-router-dom";

export default function DarkwebEmailBreachesInfo(props: {
  values: any;
  cyberScore: CyberScoreCombined;
}) {
  const { values, cyberScore } = props;

  const { domain } = useParams();

  const { approvedClaim } = useScoreClaimListQuery({ domain });

  return (
    <CyberCenterContent
      whatIsItContent={`Dark Web data breaches expose your emails and passwords, making them accessible to hackers. This can lead to unauthorized access to your systems, data theft, or impersonation.
      As a small business, particularly in accounting, data security is critical. Breaches can compromise client data, financial information, and your business's credibility.
      `}
      whyDoesItMatterContent={`Compromised work emails pose a risk of unauthorized transactions, data leaks, and communication breaches, which can severely impact your business's integrity and client trust. Attackers can simple log into your important accounts!
      The worst outcome is financial loss due to fraud, legal penalties for data breaches, loss of client trust, and significant damage to your business reputation.
      `}
      howDoYouFixItContent={`You can't stop data breaches but you can respond quickly to protect yourself. Immediately reset the passwords of affected accounts. Implement strong, unique passwords and consider using a password manager. Enable Two-Factor Authentication (2FA) and Multi-Factor Authentication (MFA) for extra security. Regularly monitor for suspicious activities and educate your employees about cybersecurity practices.
      `}
      values={[
        "Dark Web Email Breaches",
        values.BDVScore,
        approvedClaim?.resolvedBreaches
          ? `${cyberScore.BreachList?.length} breaches out of ${cyberScore.EmailList.length} total accounts`
          : values.BDVDetail,
      ]}
      cyberScore={cyberScore}
    />
  );
}
