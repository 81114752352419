import { CyberScoreCombined } from "../../../../../hooks/services/getCyberScoreCombined";
import { DarkWebTableItem } from "../types";

export function getDarkWebTableItems(cyberScore: CyberScoreCombined) {
  const { Employees, BreachList } = cyberScore;

  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "admin@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "nasy@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "juliak@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(10)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "stephenm@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "info@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  //   ...Array(24)
  //     .fill(true)
  //     .map(() => ({
  //       Email: "secretariat@ciffa.com",
  //       BreachDate: "",
  //       BreachName: "",
  //       Description: "",
  //     })),
  // ];

  const items: DarkWebTableItem[] = Employees.map((e) => ({
    email: e.Email ?? "",
    nBreaches: BreachList
      ? BreachList.filter((b) => b.Email === e.Email).length
      : 0,
    name: `${e.FirstName ?? ""} ${e.LastName ?? ""}`.trim(),
    position: e.Position ?? "",
  }))
    .sort((a, b) => {
      const aScore = (a.name ? 1 : 0) + (a.position ? 1 : 0);
      const bScore = (b.name ? 1 : 0) + (b.position ? 1 : 0);

      return bScore - aScore;
    })
    .sort((a, b) => b.nBreaches - a.nBreaches);
  return items;
}
