import { invokeLambda } from "../../../hooks/util";
import { LinkedInProfilePic } from "../types/profile-pic";

export async function getLinkedInPersonPFP(
  url: string
): Promise<LinkedInProfilePic> {
  const pfp: LinkedInProfilePic = await invokeLambda(`ProxyCurlApi-master`, {
    httpMethod: "GET",
    path: `/proxy-curl/person/profile-pic`,
    queryStringParameters: {
      linkedInUrl: url,
    },
  })
    .then(async (response) => {
      if (response.statusCode !== 200) {
        const error = new Error("Failed to get profile picture");
        error.name = response.statusCode.toString();
        throw error;
      }
      return JSON.parse(response.body) as LinkedInProfilePic;
      // return await response.body.json().then((json) => {
      //   const profilePic = json as Response;
      //   return profilePic;
      // });
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
  return pfp;
}
