import { isDomain } from "../util/isDomain";
import { get } from "../util";

export type CyberScoreCombined = {
  [key: string]:
    | string
    | number
    | -1
    | 0
    | 1
    | { Email: string; BreachDate: string; BreachName: string }[]
    | string[]
    | Employee[]
    | null
    | undefined
    | boolean;
  WI: string;
  WIScore: -1 | 0 | 1;
  PS: string;
  PSScore: -1 | 0 | 1;
  WAF: string;
  WAFScore: -1 | 0 | 1;
  ISH: string;
  ISHScore: -1 | 0 | 1;
  ESS: string;
  ESSScore: -1 | 0 | 1;
  SPF: string | null;
  DKIM: string | null;
  DMARC: string;
  SEG: string;
  SEGScore: -1 | 0 | 1;
  BDV: string;
  BDVScore: -1 | 0 | 1;
  BreachList: Breach[] | undefined;
  EmailList: string[];
  Score: number;
  Employees: Employee[];
  CompanyName: string | null;
  Logo?: string;
  Employee1?: string;
  Employee2?: string;
  Employee1Hash?: string;
  Employee2Hash?: string;
  Postcard?: boolean;
  MSP?: string | null;
  Campaign?: string | null;
  Timestamp?: string;
};

type Employee = {
  Email: string | null;
  FirstName: string | null;
  LastName: string | null;
  Position: string | null;
  SourcePage: string | null;
  Quality: "bad" | "good" | "risky" | "unverified" | null;
};

export type Breach = {
  Email: string;
  BreachDate: string;
  BreachName: string;
  Description: string;
};

export async function getCyberScoreCombined(domain: string, msp_id?: string) {
  if (isDomain(domain) === false) {
    throw new Error(`${domain} is not a valid domain.`);
  }

  const cyberScore = await get("IcebergCyberAPI", `/cyber-score/${domain}`, {
    queryParams: msp_id
      ? {
          msp_id,
          pictures: 1,
        }
      : {
          pictures: 1,
        },
  })
    .response.then(async (result) => {
      const cyberScore =
        (await result.body.json()) as any as CyberScoreCombined;
      if (cyberScore === undefined) {
        throw new Error(`Cyber Score for ${domain} is undefined.`);
      }
      return cyberScore;
    })
    .catch(() => {
      // console.error(error);
      throw new Error(
        `Could not get ${domain}'s Cyber Score, try again with another domain.`
      );
    });
  console.log(cyberScore);
  // const env = await getEnvironment();
  // const cyberScore = await invokeLambda(`SpaLambda-${env}`, {
  //   body: JSON.stringify({ domain }),
  // })
  //   .then((result) => {
  //     const cyberScore = JSON.parse(result.body) as CyberScoreCombined;
  //     if (cyberScore === undefined) {
  //       throw new Error(`Cyber Score for ${domain} is undefined.`);
  //     }
  //     return cyberScore;
  //   })
  //   .catch(() => {
  //     // console.error(error);
  //     throw new Error(
  //       `Could not get ${domain}'s Cyber Score, try again with another domain.`
  //     );
  //   });
  return cyberScore;
}
