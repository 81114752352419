import React from "react";
import { CyberScoreCombined } from "../../../hooks/services/getCyberScoreCombined";
import { Flex, Image } from "@aws-amplify/ui-react";
import { useLinkedinProfilePics } from "../../../hooks/useLinkedinProfilePics";

type Props = {
  cyberScore: CyberScoreCombined;
  domain: string | undefined;
};

export default function ProfilePictures({ cyberScore, domain }: Props) {
  // const { profilePics } = useLinkedinProfilePics(
  //   cyberScore?.Employees.map((e) => e.SourcePage).filter((s) => s !== null),
  //   {
  //     n: 4,
  //   }
  // );

  const { profilePics } = useLinkedinProfilePics(domain, cyberScore);

  // const profilePics = useMemo(() => {
  //   return [cyberScore.Employee1, cyberScore.Employee2].filter(
  //     (e) => !!e
  //   ) as string[];
  // }, [cyberScore]);

  return (
    <Flex alignItems={"center"}>
      {profilePics.slice(0, 2).map((pfp, i) => (
        <Image
          // borderRadius={"75px"}
          alt={`Linkedin Profile Picture ${i}`}
          src={pfp}
          width={"175px"}
          objectFit={"cover"}
          height={"100%"}
          maxHeight={"175px"}
          marginLeft={i === 0 ? 0 : "-60px"}
          borderRadius="100%"
        ></Image>
      ))}
    </Flex>
  );
}
