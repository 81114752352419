import * as React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import gauge from "../../../../assets/images/pdf/speedometer black.png";
import action from "../../../../assets/images/pdf/action.png";
// import { ReportStyles } from "../../monitorcompany/ReportBuilderButton";
// import { CyberScoreCombined } from "../../../types/CyberScoreCombined";
// import { Asset, NVT } from "../../../types/assetInventoryApi";
// import { KeyTakeaways } from "../../../services/gpt-generation/genKeyTakeaways";
import { PageLayout } from "../PageLayout";
import { Title } from "../Title";
import { CompanyDetails } from "../CompanyDetails";
import { KeyTakeaway } from "../KeyTakeaway";
import { LinkedinProfilePics } from "../LinkedinProfilePics";
import { DarkWebBreaches } from "../DarkWebBreaches";
import { CyberScore } from "../CyberScore";
import { Subtitle } from "../Subtitle";
import { ScoringCriteriaItems } from "../ScoringCriteriaItems";
import { TitledCommentary } from "../WhyItMatters";
import { Branding } from "../../../../features/branding/types";
import { ActionPlan } from "../../../../hooks/services/genReconActionPlan";
import { KeyTakeaways } from "../../../../hooks/services/genReconKeyTakeaways";
import { CyberScoreCombined } from "../../../../hooks/services/getCyberScoreCombined";
import DarkWebFullReport from "./DarkWebFullReport";
import DarkWebReportSummary from "./DarkWebReportSummary";
import { EmailImpersonationReport } from "./EmailImpersonationReport";
import MostRecentBreaches from "./MostRecentBreaches";
import { Company } from "../../types/company";

export type PDFDetailedScoreCardDocumentProps = {
  company: Company | string;
  logo: string;
  branding: Branding;
  cyberScore: CyberScoreCombined;
  actionPlan: ActionPlan;
  keyTakeaways: KeyTakeaways;
  whiteLabelLogo: string;
  profilePics: string[];
};

const PDFDetailedScoreCardDocument = ({
  company,
  logo,
  cyberScore,
  actionPlan,
  keyTakeaways,
  branding,
  profilePics,
}: PDFDetailedScoreCardDocumentProps): JSX.Element => {
  return (
    <Document title={"Cyber Score Card"}>
      <Page
        size="LETTER"
        orientation="landscape"
        style={{ padding: "0", margin: "0", fontSize: "13px" }}
      >
        <PageLayout
          reportStyles={branding}
          left={
            <>
              <Title
                title={`${branding.pdfTitle || "Cyber Score"} Card`}
                reportStyles={branding}
              ></Title>

              <View
                style={{
                  padding: "10px 20px 0px 0px",
                  gap: "10px",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ gap: "10px" }}>
                  <CompanyDetails
                    logoUrl={logo}
                    companyName={
                      typeof company === "string"
                        ? company
                        : company.CompanyName
                    }
                    website={
                      typeof company === "string" ? "" : company.CompanyName
                    }
                  />

                  <KeyTakeaway
                    text={keyTakeaways["key-takeaway"]}
                    reportStyles={branding}
                  />

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "15px",
                    }}
                  >
                    <LinkedinProfilePics profilePics={profilePics} />

                    <DarkWebBreaches cyberScore={cyberScore} />
                    <CyberScore cyberScore={cyberScore} branding={branding} />
                  </View>
                </View>

                <View style={{ gap: "15px" }}>
                  <Subtitle
                    icon={gauge}
                    titlepart1={"SCORING"}
                    titlepart2={"CRITERIA"}
                    color={branding.accentColor}
                  />

                  <TitledCommentary
                    reportStyles={branding}
                    header="Why it matters:"
                    body={
                      "It's more cost-effective to prevent breaches by regularly scanning your external risk surface than to pay the steep price of recovering from an attack. Find and fix weaknesses before criminals can turn them into costly problems."
                    }
                  />
                </View>
                <ScoringCriteriaItems cyberScore={cyberScore} />

                <View
                  style={{
                    gap: "2px",
                    fontSize: "10px",
                    paddingBottom: "12px",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {branding.slogan ? `${branding.slogan} |` : ""}{" "}
                    {branding.contactName} | {branding.contactEmail}
                  </Text>
                </View>
              </View>
            </>
          }
          right={
            <>
              <View>
                {branding.yourLogo !== "" ? (
                  <Image
                    src={branding.yourLogo}
                    style={{
                      objectFit: "contain",
                      height: "70px",
                      width: "100%",
                    }}
                  ></Image>
                ) : null}

                <View style={{ paddingTop: "15px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingBottom: "20px",
                      gap: "10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <View style={{ height: "40px", width: "40px" }}>
                      <Image
                        src={action}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </View>

                    <Text
                      style={{
                        fontSize: "20px",
                        paddingBottom: "2px",
                        fontWeight: "bold",
                      }}
                    >
                      ACTION PLAN
                    </Text>
                  </View>

                  <View style={{ fontSize: "10.5px", gap: "10px" }}>
                    <Text>
                      This is your 3 Step Cyber Action Plan to reduce security
                      risks:
                    </Text>

                    {actionPlan?.actions?.map((a, i) => (
                      <View key={i} style={{ gap: "2px" }}>
                        <Text style={{ fontWeight: "semibold" }}>
                          {a.title}
                        </Text>
                        <Text>{a.detail}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>

              <Text
                style={{ fontSize: "10.5px", paddingBottom: "12px" }}
                render={({ pageNumber, totalPages }) =>
                  `${new Date().toLocaleString("en-us", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })} | Page ${pageNumber}/${totalPages}`
                }
              />
            </>
          }
        />

        <DarkWebReportSummary
          cyberScore={cyberScore}
          branding={branding}
          docType="Card"
        />

        <MostRecentBreaches
          cyberScore={cyberScore}
          branding={branding}
          docType="Card"
        />
        <DarkWebFullReport
          cyberScore={cyberScore}
          branding={branding}
          docType="Card"
        />
        <EmailImpersonationReport
          cyberScore={cyberScore}
          branding={branding}
          docType="Card"
        />
      </Page>
    </Document>
  );
};

export default PDFDetailedScoreCardDocument;
